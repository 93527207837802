import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import axios from 'axios';
import 'regenerator-runtime/runtime';
require('typeface-lato');

const IG_CONTAINER = styled.div`
  margin: 40px 0px;
  @media only screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;

    place-items: flex-start center;
  }
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;

    place-items: flex-start center;
    padding: 10px;
  }
`;

const IG_POSTS_CONTAINER = styled.div`
  @media only screen and (max-width: 576px) {
    display: grid;
    grid-template-columns: repeat(1, minmax(20px, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 20px;

    place-items: flex-start center;
  }
  @media only screen and (min-width: 576px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(20px, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    place-items: flex-start center;
  }
`;

const IG_BG = styled.div`
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 10%);

  @media only screen and (max-width: 600px) {
    background-color: #fafafa;
  }
  @media only screen and (min-width: 601px) {
    border-radius: 10px;
    background-color: #fafafa;
  }
`;

const IG_IMG = styled.img`
  &&& {
    object-fit: cover;
    width: 100%;
    height: 400px !important;
    aspect-ratio: 4 / 3;

    @media only screen and (min-width: 601px) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
`;

const IG_TITLE_INNER = styled.div`
  margin: 20px;
  display: grid;
  place-items: center;
`;
const IG_TITLE = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: black;
  font-family: 'Lato';
  margin: 0;
  place-self: flex-start center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const IG_LINK = styled.a`
  text-decoration: none;
`;

const IG_LOGO_CONTAINER = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  place-items: center;
  grid-column-gap: 10px;
  margin-top: 20px;
  place-self: flex-end center;
`;

const IG_USERNAME = styled.p`
  font-size: 18px;
  font-weight: 600;
  font-family: 'Lato';
  color: #d92541;
  margin-bottom: 0px !important;
`;

const IG_LOGO = styled.img`
  &&& {
    width: 36px !important;
    height: 36px !important;
  }
`;

const Index = () => {
  const [IGposts, setIGposts] = useState([]);
  const [error, setError] = useState(false);

  const getInstagramImages = async () => {
    try {
      const { data } = await axios.get('https://europe-west3-meritta-bra.cloudfunctions.net/mbigfeed');

      setIGposts(data);
    } catch (error) {
      setIGposts([]);
    }
  };
  useEffect(() => {
    getInstagramImages();
  }, []);

  return (
    <IG_CONTAINER>
      {IGposts.length > 0 ? (
        <IG_POSTS_CONTAINER>
          {IGposts.slice(0, 3).map((post, i) => (
            <IG_BG key={i}>
              <IG_LINK href={post?.permalink} target='_blank'>
                {post?.media_type === 'IMAGE' && <IG_IMG src={post?.media_url} />}
                {post?.media_type === 'VIDEO' && <IG_IMG src={post?.thumbnail_url} />}
                {post?.media_type === 'CAROUSEL_ALBUM' && <IG_IMG src={post?.media_url} />}
                <IG_TITLE_INNER>
                  <IG_TITLE>{post?.caption}</IG_TITLE>
                  <IG_LOGO_CONTAINER>
                    <IG_LOGO src='https://storage.googleapis.com/mb_public/iglogo.png' />
                    <IG_USERNAME>{post?.username}</IG_USERNAME>
                  </IG_LOGO_CONTAINER>
                </IG_TITLE_INNER>
              </IG_LINK>
            </IG_BG>
          ))}
        </IG_POSTS_CONTAINER>
      ) : error ? (
        <IG_LOGO_CONTAINER>
          <IG_LOGO src='https://storage.googleapis.com/mb_public/iglogo.png' />
          <IG_USERNAME>Virhe ladattaessa kuvia</IG_USERNAME>
        </IG_LOGO_CONTAINER>
      ) : (
        <IG_LOGO_CONTAINER>
          <IG_LOGO src='https://storage.googleapis.com/mb_public/iglogo.png' />
          <IG_USERNAME>Ladataan...</IG_USERNAME>
        </IG_LOGO_CONTAINER>
      )}
    </IG_CONTAINER>
  );
};

ReactDOM.render(React.createElement(Index), document.getElementById('igfeed'));
